import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Bill from "layouts/dtypefunction/components/Bill";
// import SuiInput from "components/SuiInput";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import SuiButton from "components/SuiButton";
import { Grid } from "@mui/material";
import borders from "assets/theme/base/borders";
// const params = new URLSearchParams(document.location.search);

function BillingInformation() {
  const { borderWidth, borderColor } = borders;
  // const project = params.get("project");
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [colsdtype, setColsDtype] = useState([]);
  const [cname, setCName] = useState("");
  const [enc, setEnc] = useState("");
  const [send, setSend] = useState("");
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  const Encoding = () => {
    if (cname == "") {
      setMes(undefined);
      return setError("You must tick The Check Box");
    } else if (enc == "") {
      setMes(undefined);
      return setError("You must Select Encode Method");
    }

    {
      axios.get(`/encoding/${send}`).then((res) => {
        if (res.data.status == 1) {
          window.location.reload();
        } else if (res.data.status == 0) {
          {
            setMes("");
          }
          {
            setError("Encoding Failed ");
          }
        } else if (res.data.status == -1) {
          {
            setMes("");
          }
          {
            setError("Label Encoding Error please Check Coulumn Suitable for Encode ");
          }
        } else if (res.data.status == -2) {
          {
            setMes("");
          }
          {
            setError("Dummy Encoding Error please Check Coulumn Suitable for Encode ");
          }
        }
      });
      decrementNum();
    }
  };

  useEffect(() => {
    if (num == 0) {
      axios.get(`/dtypechange/${project}`).then((res) => setColsDtype(res.data.coldtype));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Data Encoding
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
          </SuiBox>
        )}
        {mes && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
          </SuiBox>
        )}
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Card id="delete-account">
            <SuiBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <SuiBox
                    border={`${borderWidth[1]} solid ${borderColor}`}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <select
                      name="dtcol"
                      id="dtcol"
                      style={{
                        width: "33%",
                        height: 35,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setCName(event.target.value),
                          setSend(
                            String(project) + "=" + String(cname) + "=" + String(event.target.value)
                          );
                      }}
                    >
                      <option value="">--SELECT COLUMN--</option>
                      {colsdtype.map(function (objt, index) {
                        return (
                          <option value={objt.col} key={index}>
                            {objt.col}
                          </option>
                        );
                      })}
                    </select>
                    <span>&nbsp;&nbsp;&nbsp;</span>

                    <select
                      name="dtype"
                      id="d_type"
                      style={{
                        width: "33%",
                        height: 35,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setEnc(event.target.value),
                          setSend(
                            String(project) + "=" + String(cname) + "=" + String(event.target.value)
                          );
                      }}
                    >
                      <option value="">--SELECT METHOD--</option>
                      <option value="label">LABEL ENCODING</option>
                      <option value="dummy">DUMMY VARIABLE</option>
                      {/* <option value="one">ONE HOT</option> */}
                    </select>

                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <SuiButton
                      style={{ width: "33%", height: 35 }}
                      variant="gradient"
                      buttonColor="dark"
                      onClick={() => Encoding()}
                    >
                      encode
                    </SuiButton>
                  </SuiBox>

                  {cname && (
                    <SuiTypography variant="caption" textColor="secondary">
                      Column : {cname}
                    </SuiTypography>
                  )}
                  <p></p>
                  {enc && (
                    <SuiTypography variant="caption" textColor="secondary">
                      Encode : {enc}
                    </SuiTypography>
                  )}
                </Grid>
              </Grid>
            </SuiBox>
          </Card>

          {colsdtype.map(function (objt, index) {
            return (
              <div key={index}>
                <Bill
                  name={objt.col}
                  company={objt.datatypes}
                  email={objt.coltyp}
                  vat={objt.n_row}
                />
              </div>
            );
          })}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;
