import Button from "@material-ui/core/Button";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import SuiInput from "components/SuiInput";
// Custom styles for the Tables
import styles from "layouts/tables/styles";

import image from "assets/images/team-2.jpg";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import { useAuth } from "../../auth-context/auth.context";
import ProfilesList from "examples/ProfilesList";
import profilesListData from "layouts/profile/data/profilesListData_1";

function Tables(outlined) {
  const classes = styles();
  const { columns, rows } = authorsTableData;

  // const { columns: prCols, rows: prRows } = projectsTableData;
  const [pname, setpName] = useState("");
  const [file, setFile] = useState("");
  const [error, setError] = useState(undefined);
  // const [success, setSuccess] = useState(undefined);
  const [datalake, setDatalake] = useState([]);
  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");
  // const [usern, setUser] =useState();
  let { user } = useAuth();

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("/dlake", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setDatalake(res.data))
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const deletel_row_df = (val) => {
    var result = confirm("Project " + val + " Confirm To Delete?");
    if (result) {
      console.log(val);
      axios.delete(`/dlake/${val}`).then((res) => {
        console.log(res);
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
  };

  const newdf = () => {
    const uploadData = new FormData();
    if (pname == "") {
      setMes(undefined);
      return setError("You must Enter File Name");
    } else if (file == "") {
      setMes(undefined);
      return setError("You must Upload A File");
    } else {
      try {
        uploadData.append("pname", pname);
        uploadData.append("user", user["username"]);
        uploadData.append("file", file, file.name);
        fetch("https://mizube.oud.ai/api/users/dlake", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status == 0) {
              {
                setMes("");
              }
              {
                setError(file.name + " Can't Upload, Project Name Already Exist");
              }
            }

            if (data.status == 1) {
              {
                setError("");
              }
              {
                setMes(file.name + " Successfully Uploaded");
              }
              decrementNum();
            }

            if (data.status == -1) {
              {
                setMes("");
              }
              {
                setError("Unknown Error");
              }
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">RAW DATA</SuiTypography>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table columns={columns} rows={rows} />
            </SuiBox>

            <SuiBox customClass={classes.tables_table}>
              <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                  <table className="MuiTable-root css-w793m5-MuiTable-root">
                    <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                      <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          FILE
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          EXTENTION
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          STATUS
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          SIZE GB
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {datalake.map((dlak, index) => {
                        return (
                          <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root" key={index}>
                            <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                              <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                <SuiBox mr={2}>
                                  <SuiAvatar src={image} alt={"name"} size="sm" variant="rounded" />
                                </SuiBox>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography variant="button" fontWeight="medium">
                                    {dlak.pname}
                                  </SuiTypography>
                                  <SuiTypography variant="caption" textColor="secondary">
                                    {dlak.fname}
                                  </SuiTypography>
                                </SuiBox>
                              </SuiBox>
                            </td>

                            <td>
                              <SuiBox display="flex" flexDirection="column">
                                <SuiTypography
                                  variant="caption"
                                  fontWeight="medium"
                                  textColor="text"
                                >
                                  .{dlak.extensions} File
                                </SuiTypography>
                                <SuiTypography variant="caption" textColor="secondary">
                                  Date Uploaded:{dlak.dateupl}
                                </SuiTypography>
                              </SuiBox>
                            </td>
                            <td>
                              <SuiBadge
                                variant="gradient"
                                badgeContent={dlak.statusdl}
                                color={dlak.statuscolor}
                                size="extra-small"
                              />
                            </td>
                            <td>
                              <SuiTypography
                                variant="caption"
                                textColor="secondary"
                                fontWeight="medium"
                              >
                                {" "}
                                {dlak.fsize} GB / {String(dlak.fsize * 1000).slice(0, 6)} MB
                              </SuiTypography>
                            </td>
                            <td align="center">
                              <Icon
                                className="cursor-pointer"
                                color="error"
                                fontSize="medium"
                                onClick={() => deletel_row_df(dlak.pname)}
                              >
                                delete
                              </Icon>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </SuiBox>
          </Card>
        </SuiBox>
        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiBox mb={1} ml={0.5}>
                      <SuiInput
                        value={pname}
                        onChange={(event) => {
                          setpName(event.target.value);
                        }}
                        type="text"
                        placeholder="Project Name"
                      />
                    </SuiBox>
                    <SuiBox mb={1} ml={0.5}>
                      <input
                        id="file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(evt) => setFile(evt.target.files[0])}
                      />
                      <label htmlFor="file">
                        <Button variant="contained" color="primary" component="span" fullWidth>
                          <Icon className="font-bold">add</Icon>
                          &nbsp; add file
                        </Button>
                      </label>
                      <SuiBox mt={2} mb={2} textAlign="center">
                        <h6 style={{ color: "grey", textAlign: "center" }}>{file.name}</h6>
                      </SuiBox>
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="dark"
                        onClick={() => newdf()}
                        fullWidth
                      >
                        upload
                      </SuiButton>
                    </SuiBox>
                  </div>
                  {error && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                    </SuiBox>
                  )}
                  {mes && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                    </SuiBox>
                  )}
                </SuiBox>
              </Card>
              <Card></Card>
            </Grid>

            <Grid item xs={12} md={6} xl={8}>
              <ProfilesList title="MIZU MODULES" profiles={profilesListData} />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
