import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import profilesListData from "layouts/profile/data/profilesListData_";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";

function Overview() {
  const [num, setNum] = useState(0);
  const [Pinfo, setPInfo] = useState("");
  const [Pinfoh, setPInfoh] = useState("");
  const [Binfo, setBInfo] = useState("");
  const [Binfoh, setBInfoh] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  useEffect(() => {
    if (num == 0) {
      axios
        .get("/infotxt")
        .then(
          (res) => (
            setPInfo(res.data[0].txt2),
            setPInfoh(res.data[0].txt3),
            setBInfo(res.data[0].txt6),
            setBInfoh(res.data[0].txt4)
          )
        )
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title={Pinfoh}
              description={Pinfo}
              title1={Binfoh}
              description1={Binfo}
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <ProfilesList title="Rout" profiles={profilesListData} />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Card>
          <SuiBox pt={2} px={2}>
            <SuiBox mb={0.5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Projects
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1}>
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                Architects design houses
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor1}
                  title="DATA LAKE"
                  description="A data lake is a centralized repository designed to store, process, and secure large amounts of structured, semistructured, and unstructured data"
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor2}
                  title="DATA WAREHOUSE"
                  description="A data warehouse is an enterprise system used for the analysis and reporting of structured and semi-structured data from multiple sources"
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor3}
                  title="PROJECT"
                  description="A machine learning model is defined as a mathematical representation of the output of the training process. Machine learning is the study of different algorithms"
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <PlaceholderCard title={{ variant: "h5", text: "New project" }} outlined />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>

      <Footer />
    </DashboardLayout>
  );
}
export default Overview;
