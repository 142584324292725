import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Invoice from "layouts/algmtuning/components/Invoice";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import { useAuth } from "auth-context/auth.context";
// import SuiAvatar from "components/SuiAvatar";
// import image from "assets/images/project.png";

function Invoices() {
  const [num, setNum] = useState(0);
  const [project, setProject] = useState([]);
  let { user } = useAuth();
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  // function decrementNum() {setNum(prev => prev - 1);}
  useEffect(() => {
    if (num == 0) {
      axios
        .get("/project", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setProject(res.data))
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card id="delete-account">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          Last Created
        </SuiTypography>
        <SuiButton variant="outlined" buttonColor="info" size="small" href={"/tablesproj"}>
          view models
          {/* <SuiButton variant="gradient" buttonColor="dark"><a style={{color:"#fff"}} href={"/cleanfunction/?project="+pname}><Icon className="font-bold" >settings</Icon>&nbsp;&nbsp;&nbsp;DATA CLEANING</a></SuiButton> */}
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {project.map((project, index) => {
            return (
              <p key={index}>
                <Invoice date={project.pname} id={project.algorithm} price={project.score} />
              </p>
            );
          })}
        </SuiBox>
      </SuiBox>
      <SuiBox></SuiBox>
    </Card>
  );
}

export default Invoices;
