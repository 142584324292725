import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Bill from "layouts/dtypefunction/components/Bill";
import SuiInput from "components/SuiInput";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import SuiButton from "components/SuiButton";
import { Grid } from "@mui/material";
import borders from "assets/theme/base/borders";
// const params = new URLSearchParams(document.location.search);

function BillingInformation() {
  const { borderWidth, borderColor } = borders;
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [colsdtype, setColsDtype] = useState([]);
  const [cname, setCName] = useState("");
  const [rg, setRg] = useState("");
  const [send, setSend] = useState("");
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  const RglrExp = () => {
    if (cname == "") {
      setMes(undefined);
      return setError("You must Select Column");
    } else if (rg == "") {
      setMes(undefined);
      return setError("Type Unwanted Charactor");
    }
    {
      axios.get(`/regularexp/${send}`).then((res) => {
        if (res.data.status == 1) {
          window.location.reload();
        } else if (res.data.status == 0) {
          {
            setMes("");
          }
          {
            setError("Operation Failed ");
          }
        } else if (res.data.status == -1) {
          {
            setMes("");
          }
          {
            setError("No Changes ");
          }
        }
      });
      decrementNum();
    }
  };

  useEffect(() => {
    if (num == 0) {
      axios.get(`/dtypechange/${project}`).then((res) => setColsDtype(res.data.coldtype));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Remove Specific Charactor Of Values in Column
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
          </SuiBox>
        )}
        {mes && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
          </SuiBox>
        )}
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Card id="delete-account">
            <SuiBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <SuiBox
                    border={`${borderWidth[1]} solid ${borderColor}`}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <select
                      name="dtcol"
                      id="dtcol"
                      style={{
                        width: "45%",
                        height: 35,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setCName(event.target.value),
                          setSend(
                            String(project) + "=" + String(cname) + "=" + String(event.target.value)
                          );
                      }}
                    >
                      <option value="">--SELECT COLUMN--</option>
                      {colsdtype.map(function (objt, index) {
                        return (
                          <option value={objt.col} key={index}>
                            {objt.col}
                          </option>
                        );
                      })}
                    </select>
                    <span>&nbsp;&nbsp;&nbsp;</span>

                    <SuiBox style={{ width: "45%", height: 35 }}>
                      <SuiInput
                        onChange={(event) => {
                          setRg(event.target.value),
                            setSend(
                              String(project) +
                                "=" +
                                String(cname) +
                                "=" +
                                String(event.target.value)
                            );
                        }}
                        type="text"
                        placeholder="Type Unwanted Charactor"
                      />
                    </SuiBox>

                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <SuiButton
                      style={{ width: "45%", height: 35 }}
                      variant="gradient"
                      buttonColor="dark"
                      onClick={() => RglrExp()}
                    >
                      update
                    </SuiButton>
                  </SuiBox>

                  {cname && (
                    <SuiTypography variant="caption" textColor="secondary">
                      Column : {cname}
                    </SuiTypography>
                  )}
                  <p></p>
                  {rg && (
                    <SuiTypography variant="caption" textColor="secondary">
                      Charactor : {rg}
                    </SuiTypography>
                  )}
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
          {colsdtype.map(function (objt, index) {
            return (
              <div key={index}>
                <Bill
                  name={objt.col}
                  company={objt.datatypes}
                  email={objt.coltyp}
                  vat={objt.n_row}
                />
              </div>
            );
          })}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;
