import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import { useEffect, useState } from "react";
import SuiButton from "components/SuiButton";
import { Grid } from "@mui/material";
import borders from "assets/theme/base/borders";
// const params = new URLSearchParams(document.location.search);
const hash = window.location.hash;
const queryString = hash.split("?")[1];
const params = new URLSearchParams(queryString);

function BillingInformation() {
  const { borderWidth, borderColor } = borders;
  const ticket = params.get("ticket");
  const [dtype, setDType] = useState("");
  const [mes, setMes] = useState("");
  const [clickinfo, setClickinfo] = useState("");
  const [error, setError] = useState(undefined);

  const ticketupdate = () => {
    console.log(window.location.search);
    if (ticket == "") {
      setMes(undefined);
      return setError("Ticket no invalid");
    } else if (dtype == "") {
      setMes(undefined);
      return setError("You must Select status");
    } else {
      setClickinfo("Loading...");
      const uploadData = new FormData();
      uploadData.append("ticket", ticket);
      uploadData.append("status", dtype);
      fetch("https://mizube.oud.ai/api/users/demoobj", {
        method: "PUT",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          setClickinfo("");
          if (data.status == -1) {
            {
              setMes("");
            }
            {
              setError("Ticket invalid");
            }
          }

          if (data.status == 1) {
            {
              setError("");
            }
            {
              setMes("Ticket Updated");
            }
          }

          if (data.status == 0) {
            {
              setMes("");
            }
            {
              setError("Unknown Error");
            }
          }
        });
    }
  };

  useEffect(() => {});

  return (
    <Card>
      <SuiBox pt={3} px={3}>
        <SuiTypography variant="h6" fontWeight="medium">
          REPORT TICKET
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        {error && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
          </SuiBox>
        )}
        {mes && (
          <SuiBox mt={2} mb={2} textAlign="center">
            <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
          </SuiBox>
        )}
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Card>
            <SuiBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  {clickinfo}
                  <SuiBox
                    border={`${borderWidth[1]} solid ${borderColor}`}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <SuiBadge
                      variant="gradient"
                      badgeContent={ticket}
                      color="primary"
                      size="extra-large"
                    />
                  </SuiBox>

                  {ticket && (
                    <SuiTypography variant="caption" textColor="secondary">
                      Violation ID : {ticket}
                    </SuiTypography>
                  )}
                  <p></p>
                  {dtype && (
                    <SuiTypography variant="caption" textColor="secondary">
                      Status : {dtype}
                    </SuiTypography>
                  )}
                </Grid>

                <Grid item xs={12} md={12}>
                  <SuiBox
                    border={`${borderWidth[1]} solid ${borderColor}`}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <select
                      name="dtype"
                      id="d_type"
                      style={{
                        width: "70%",
                        height: 35,
                        color: "#525557",
                        backgroundColor: "#e4e9eb",
                        border: "3px solid #e4e9eb",
                      }}
                      onChange={(event) => {
                        setDType(event.target.value);
                      }}
                    >
                      <option value="">--STATUS--</option>
                      <option value="closed">CLOSED</option>
                      <option value="escalate">ESCALATE</option>
                    </select>

                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <SuiButton
                      style={{ width: "30%", height: 35 }}
                      variant="gradient"
                      buttonColor="dark"
                      onClick={() => ticketupdate()}
                    >
                      update
                    </SuiButton>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;
