import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import styles from "layouts/tables/styles";
import image from "assets/images/team-2.jpg";
import SuiAvatar from "components/SuiAvatar";
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect } from "react";
import retail from "assets/images/retail.jpg";
import school from "assets/images/shool.jpg";
import restau from "assets/images/rest.jpg";
import restauchain from "assets/images/restchain.jpg";
import secure from "assets/images/security.jpg";
import hotel from "assets/images/hotel.jpg";
import logistics from "assets/images/logistics.jpg";
import marketing from "assets/images/marketing.jpg";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
// import axios from "axios";
// import { useAuth } from "../../auth-context/auth.context";

function Tables() {
  const classes = styles();
  const { columns, rows } = authorsTableData;
  // let { user } = useAuth();

  useEffect(() => {});
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={12}>
              <SuiBox mb={3}>
                <Card>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SuiTypography variant="h6">SPECIAL MACHINE LEARNING SOLUTIONS </SuiTypography>
                  </SuiBox>
                  <SuiBox customClass={classes.tables_table}>
                    <Table columns={columns} rows={rows} />
                  </SuiBox>

                  <SuiBox customClass={classes.tables_table}>
                    <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                      <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                        <table style={{ width: "100%" }}>
                          <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                            <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                SOLUTION
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                PROBLEM
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                DESCRIPTION
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                SELECT
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                                <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                  <SuiBox mr={2}>
                                    <SuiAvatar
                                      src={image}
                                      alt={"name"}
                                      size="sm"
                                      variant="rounded"
                                    />
                                  </SuiBox>
                                  <SuiBox display="flex" flexDirection="column">
                                    <SuiTypography variant="button" fontWeight="medium">
                                      {" "}
                                      RESTAURANT SALES
                                    </SuiTypography>
                                    <SuiTypography variant="caption" textColor="secondary">
                                      How Much Sales Today ?
                                    </SuiTypography>
                                  </SuiBox>
                                </SuiBox>
                              </td>

                              <td>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textColor="text"
                                  >
                                    How much i should prepare today ?
                                  </SuiTypography>
                                </SuiBox>
                              </td>
                              <td>
                                <SuiTypography
                                  variant="caption"
                                  textColor="secondary"
                                  fontWeight="medium"
                                >
                                  TIME SERIES FORCASTING
                                </SuiTypography>
                              </td>
                              <td>
                                <SuiTypography
                                  variant="caption"
                                  textColor="secondary"
                                  fontWeight="medium"
                                >
                                  {" "}
                                  <a href={"/#/restaurant1"}>Lets Start</a>
                                </SuiTypography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </SuiBox>
                </Card>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <SuiBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={restau}
              title="Small Restaurant"
              description="Machine learning can significantly benefit small restaurants by optimizing various aspects of their operations and enhancing the customer experience"
              action={{
                type: "internal",
                route: "/restaurant1",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={restauchain}
              title="Restaurant Chain"
              description="Machine learning can provide substantial benefits for restaurant chains by streamlining operations, improving customer experience, and driving growth"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={hotel}
              title="Hotel"
              description="Machine learning can significantly enhance various aspects of hotel operations, from improving guest experiences to optimizing operational efficiency and boosting revenue"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={retail}
              title="Retail"
              description="Machine learning offers transformative potential for the retail industry by optimizing various aspects of business operations and enhancing customer interactions"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={logistics}
              title="Logistics"
              description="Machine learning can significantly enhance logistics operations by improving efficiency, reducing costs, and optimizing various aspects of the supply chain"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={school}
              title="Schools"
              description="Machine learning can greatly benefit educational institutions by enhancing learning experiences, optimizing administrative tasks, and supporting student outcomes"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={marketing}
              title="Marketing"
              description="Machine learning can transform marketing strategies by providing deeper insights, enhancing targeting, and optimizing campaigns"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultProjectCard
              image={secure}
              title="Security"
              description="In the context of security marketing, machine learning can play a crucial role in enhancing strategies and tactics to effectively promote security products and services"
              action={{
                type: "internal",
                route: "/pages/profile/profile-overview",
                color: "info",
                label: "view",
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
