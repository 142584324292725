// import Button from "@material-ui/core/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import styles from "layouts/tables/styles";
import SuiInput from "components/SuiInput";
import image from "assets/images/team-2.jpg";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import { useAuth } from "../../auth-context/auth.context";

function Tables() {
  const classes = styles();
  const { columns, rows } = authorsTableData;
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");
  const [pname, setpName] = useState("");
  const [pno, setpNo] = useState("");
  const [datalake, setDatalake] = useState([]);
  const [num, setNum] = useState(0);
  const [merge, setMerge] = useState("");
  const [data, setData] = useState("");
  const [erro, setErro] = useState("");
  let { user } = useAuth();
  const [selected, setSelected] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("/dlake", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setDatalake(res.data))
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });

  const deletel_row_df = (val) => {
    var result = confirm("Project " + val + " Confirm To Delete?");
    if (result) {
      console.log(val);
      axios.delete(`/dlake/${val}`).then((res) => {
        console.log(res);
        decrementNum();
      });
      decrementNum();
      {
        setMes("");
      }
      {
        setError("");
      }
    }
    decrementNum();
  };

  const vtot = () => {
    const uploadData = new FormData();
    console.log(typeof pno);
    try {
      uploadData.append("flag", 0);
      uploadData.append("selected", selected);
      uploadData.append("pno", pno);
      uploadData.append("user", user["username"]);
      fetch("https://mizube.oud.ai/api/users/mergedf", {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          setData(data.data);
          setMerge(data.merge);
          setErro(data.error);
          decrementNum();
        })

        .catch((error) => console.error(error));
    } catch {
      {
        setMerge("");
      }
      {
        setError("Upload File & Project Name Properly");
      }
    }

    setError(undefined);
    decrementNum();
    return setMerge(merge);
  };

  const mergedf = () => {
    const uploadData = new FormData();
    try {
      uploadData.append("flag", 1);
      uploadData.append("data", data);
      uploadData.append("pname", pname);
      uploadData.append("user", user["username"]);

      fetch("https://mizube.oud.ai/api/users/mergedf", {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          setData(data.data);
          setMerge(data.merge);
          setErro(data.error);
          decrementNum();
        })
        .catch((error) => console.error(error));
    } catch {
      {
        setMerge("");
      }
      {
        setError("Upload File & Project Name Properly");
      }
    }

    setError(undefined);
    decrementNum();
    return setMerge(merge);
  };

  function onChange(i) {
    setSelected((prev) => (i === prev ? null : i));
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">MERGE DATA</SuiTypography>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table columns={columns} rows={rows} />
            </SuiBox>

            <SuiBox customClass={classes.tables_table}>
              <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                  <table style={{ width: "100%" }}>
                    <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                      <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          FILE
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          EXTENTION
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          STATUS
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          SIZE GB
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          SELECT
                        </th>
                        <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {datalake.map((dlak, index) => {
                        if ((dlak.extensions == "CSV") | (dlak.extensions == "XLSX")) {
                          return (
                            <tr
                              className="MuiTableRow-root css-iw4ra7-MuiTableRow-root"
                              key={index}
                            >
                              <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                                <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                  <SuiBox mr={2}>
                                    <SuiAvatar
                                      src={image}
                                      alt={"name"}
                                      size="sm"
                                      variant="rounded"
                                    />
                                  </SuiBox>
                                  <SuiBox display="flex" flexDirection="column">
                                    <SuiTypography variant="button" fontWeight="medium">
                                      {dlak.pname}
                                    </SuiTypography>
                                    <SuiTypography variant="caption" textColor="secondary">
                                      {dlak.fname}
                                    </SuiTypography>
                                  </SuiBox>
                                </SuiBox>
                              </td>

                              <td>
                                <SuiBox display="flex" flexDirection="column">
                                  <SuiTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textColor="text"
                                  >
                                    .{dlak.extensions} File
                                  </SuiTypography>
                                  <SuiTypography variant="caption" textColor="secondary">
                                    Date Uploaded:{dlak.dateupl}
                                  </SuiTypography>
                                </SuiBox>
                              </td>
                              <td>
                                <SuiBadge
                                  variant="gradient"
                                  badgeContent={dlak.statusdl}
                                  color={dlak.statuscolor}
                                  size="extra-small"
                                />
                              </td>
                              <td>
                                <SuiTypography
                                  variant="caption"
                                  textColor="secondary"
                                  fontWeight="medium"
                                >
                                  {" "}
                                  {dlak.fsize} GB / {String(dlak.fsize * 1000).slice(0, 6)} MB
                                </SuiTypography>
                              </td>
                              {/* <td ><SuiTypography  variant="caption" textColor="secondary" fontWeight="medium"> <a href={"/dataclean/?project="+dlak.pname}>SELECT</a></SuiTypography></td> */}
                              <td>
                                <input
                                  type="checkbox"
                                  checked={index == selected}
                                  onChange={() => onChange(index)}
                                />
                              </td>
                              <td align="center">
                                <Icon
                                  className="cursor-pointer"
                                  color="error"
                                  fontSize="medium"
                                  onClick={() => deletel_row_df(dlak.pname)}
                                >
                                  delete
                                </Icon>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </SuiBox>
          </Card>
          <SuiBox mt={5} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={6}></Grid>
              <Grid item xs={12} md={6} xl={2}>
                <SuiInput
                  value={pno}
                  onChange={(event) => {
                    setpNo(event.target.value);
                  }}
                  type="text"
                  placeholder="No of Dataframe Merge"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiButton variant="gradient" buttonColor="dark" onClick={() => vtot()} fullWidth>
                    Add to que
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
        <Grid item xs={12} md={6} xl={4}>
          {merge && <h6 style={{ color: "#0bd60b" }}>{merge}</h6>}
          {data && <h6>{"MERGE ORDER : " + data}</h6>}
          {erro && <h6 style={{ color: " #d6290b" }}>{erro}</h6>}
        </Grid>
        <br></br>
        {data && (
          <Grid item xs={12} md={6} xl={3}>
            <SuiInput
              value={pname}
              onChange={(event) => {
                setpName(event.target.value);
              }}
              type="text"
              placeholder="New fileName"
            />
          </Grid>
        )}
        {error && <h6 style={{ color: " #d6290b" }}>{error}</h6>}
        {mes && <h6 style={{ color: " #d6290b" }}>{mes}</h6>}

        <br></br>
        <Grid item xs={12} md={6} xl={3}>
          {data && (
            <SuiButton variant="gradient" buttonColor="dark" onClick={() => mergedf()} fullWidth>
              {" "}
              Merge DF save to Data Lake
            </SuiButton>
          )}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
