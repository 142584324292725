import Button from "@material-ui/core/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useState } from "react";
import { useAuth } from "../../auth-context/auth.context";

function Tables(outlined) {
  const classes = styles();
  const [file, setFile] = useState("");
  const [error, setError] = useState(undefined);
  //   const [num, setNum] = useState(0);
  const [text, setText] = useState("");
  const [mes, setMes] = useState("");
  let { user } = useAuth();

  //   function decrementNum() {
  //     setNum((prev) => prev - 1);
  //   }

  const newdf = () => {
    const uploadData = new FormData();
    if (file == "") {
      setMes("");
      return setError("You must Upload A File");
    } else {
      try {
        uploadData.append("user", user["username"]);
        uploadData.append("file", file, file.name);
        fetch("https://mizube.oud.ai/api/users/imgtotxt", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.status == 0) {
              {
                setMes("");
              }
              {
                setError("Can't Upload");
              }
            }

            if (data.status == 1) {
              {
                setError("");
              }
              {
                setMes("Image to Text");
              }
              {
                setText(data.text);
              }
              // decrementNum();
            }
          })
          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Error");
        }
      }
    }
    // decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}></SuiBox>
        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <Card
                raised
                className={clsx(classes.placeholderCard, {
                  [classes.placeholderCard_outlined]: outlined,
                })}
              >
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="100%"
                  p={3}
                >
                  <div>
                    <SuiBox mb={1} ml={0.5}>
                      <input
                        id="file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(evt) => setFile(evt.target.files[0])}
                      />
                      <label htmlFor="file">
                        <Button variant="contained" color="primary" component="span" fullWidth>
                          <Icon className="font-bold">add</Icon>
                          &nbsp; add file
                        </Button>
                      </label>
                      <SuiBox mt={2} mb={2} textAlign="center">
                        <h6 style={{ color: "grey", textAlign: "center" }}>{file.name}</h6>
                      </SuiBox>
                    </SuiBox>

                    <SuiBox mb={1} ml={0.5}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="dark"
                        onClick={() => newdf()}
                        fullWidth
                      >
                        upload
                      </SuiButton>
                    </SuiBox>
                  </div>
                  {error && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                    </SuiBox>
                  )}
                  {mes && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                    </SuiBox>
                  )}
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
          <Card>
            {text && (
              <SuiInput value={text} type="text" placeholder="Content" multiline rows={10} />
            )}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
