import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import styles from "layouts/dashboard/components/WorkWithTheRockets/styles";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
function WorkWithTheRockets() {
  const classes = styles();
  const [num, setNum] = useState(0);
  // const [binfo, setBinfo] = useState("");
  const [binfoh, setBinfoh] = useState("");
  const [binfos, setBinfos] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  useEffect(() => {
    if (num == 0) {
      axios
        .get("/infotxt")
        .then(
          (res) => (
            // setBinfo(res.data[0].txt2),
            setBinfoh(res.data[0].txt4), setBinfos(res.data[0].txt6)
          )
        )
        .catch((err) => console.log(err));
    }

    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card className="h-100">
      <SuiBox position="relative" height="100%" p={2}>
        <SuiBox customClass={classes.workWithTheRockets_content}>
          <SuiBox mb={3} pt={1}>
            <SuiTypography variant="h5" textColor="white" fontWeight="bold">
              {binfoh}
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" textColor="white">
              {/* {binfo} */}
              {binfos}
            </SuiTypography>
          </SuiBox>
          <SuiTypography
            component="a"
            href="/#/profile"
            variant="button"
            textColor="white"
            fontWeight="medium"
            customClass={classes.workWithTheRockets_button}
          >
            Read More
            <Icon className="font-bold">arrow_forward</Icon>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default WorkWithTheRockets;
