import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import styles from "layouts/tables/styles";
import image from "assets/images/team-2.jpg";
import SuiAvatar from "components/SuiAvatar";
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import { useAuth } from "../../auth-context/auth.context";

function Tables() {
  const classes = styles();
  const { columns, rows } = authorsTableData;
  // const [pname, setpName] = useState("");
  // const [file, setFile] = useState("");
  // const [error, setError] = useState(undefined);
  // const [success, setSuccess] = useState(undefined);
  const [datalake, setDatalake] = useState([]);
  const [num, setNum] = useState(0);
  // const [mes, setMes] = useState("");
  // const [usern, setUser] =useState();
  let { user } = useAuth();

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  // function decrementNum() {
  //   setNum((prev) => prev - 1);
  // }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("/dlake", {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setDatalake(res.data))
        .catch((err) => console.log(err));
    }
    console.log(num);
    if (num < 1) {
      incrementNum();
    }
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={12}>
              <SuiBox mb={3}>
                <Card>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SuiTypography variant="h6">RAW DATA - MACHINE LEARNING - CSV</SuiTypography>
                  </SuiBox>
                  <SuiBox customClass={classes.tables_table}>
                    <Table columns={columns} rows={rows} />
                  </SuiBox>

                  <SuiBox customClass={classes.tables_table}>
                    <div className="makeStyles-suiBox-13 makeStyles-suiBox-274 makeStyles-tables_table-205 MuiBox-root css-0">
                      <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
                        <table style={{ width: "100%" }}>
                          <thead className="makeStyles-suiBox-13 makeStyles-suiBox-276 MuiBox-root css-0">
                            <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                FILE
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                EXTENTION
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                SIZE GB
                              </th>
                              <th className="makeStyles-suiBox-13 makeStyles-suiBox-278 MuiBox-root css-qbmeyw">
                                SELECT
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {datalake.map((dlak, index) => {
                              if (dlak.extensions == "CSV") {
                                return (
                                  <tr
                                    className="MuiTableRow-root css-iw4ra7-MuiTableRow-root"
                                    key={index}
                                  >
                                    <td className="makeStyles-suiBox-13 makeStyles-suiBox-288 MuiBox-root css-ush7lw">
                                      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                        <SuiBox mr={2}>
                                          <SuiAvatar
                                            src={image}
                                            alt={"name"}
                                            size="sm"
                                            variant="rounded"
                                          />
                                        </SuiBox>
                                        <SuiBox display="flex" flexDirection="column">
                                          <SuiTypography variant="button" fontWeight="medium">
                                            {dlak.pname}
                                          </SuiTypography>
                                          <SuiTypography variant="caption" textColor="secondary">
                                            {dlak.fname}
                                          </SuiTypography>
                                        </SuiBox>
                                      </SuiBox>
                                    </td>

                                    <td>
                                      <SuiBox display="flex" flexDirection="column">
                                        <SuiTypography
                                          variant="caption"
                                          fontWeight="medium"
                                          textColor="text"
                                        >
                                          .{dlak.extensions} File
                                        </SuiTypography>
                                      </SuiBox>
                                    </td>
                                    <td>
                                      <SuiTypography
                                        variant="caption"
                                        textColor="secondary"
                                        fontWeight="medium"
                                      >
                                        {" "}
                                        {dlak.fsize} GB / {String(dlak.fsize * 1000).slice(0, 6)} MB
                                      </SuiTypography>
                                    </td>
                                    <td>
                                      <SuiTypography
                                        variant="caption"
                                        textColor="secondary"
                                        fontWeight="medium"
                                      >
                                        {" "}
                                        <a href={"/#/dataclean/?project=" + dlak.pname}>ML</a>
                                      </SuiTypography>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </SuiBox>
                </Card>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
