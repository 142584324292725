import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import SuiTypography from "components/SuiTypography";
import Icon from "@mui/material/Icon";
import Transaction from "layouts/dataploting/components/Transaction";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import borders from "assets/theme/base/borders";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Bill from "layouts/dataploting/components/Bill";

function Billing() {
  // const params = new URLSearchParams(document.location.search);
  // const project = params.get("project");
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const { borderWidth, borderColor } = borders;
  const [num, setNum] = useState(0);
  const [colsdtype, setColsDtype] = useState([]);
  const [cname, setCName] = useState("");
  const [cname_hist, setCName_hist] = useState("");
  const [cname_scatter1, setCName_Scatter1] = useState("");
  const [cname_scatter2, setCName_Scatter2] = useState("");
  const [cname_line1, setCName_Line1] = useState("");
  const [cname_line2, setCName_Line2] = useState("");
  const [cname_pie, setCName_pie] = useState("");
  const [quart1, setQUARTILE1] = useState("");
  const [quart2, setQUARTILE2] = useState("");
  const [n_upper, setUpper] = useState("");
  const [n_lower, setLower] = useState("");

  const [uplo, setUploout] = useState([]);
  const [status, setStatus] = useState("");
  const [status_hist, setStatus_hist] = useState("");
  const [status_scat, setStatus_scat] = useState("");
  const [status_pie, setStatus_pie] = useState("");
  const [status_line, setStatus_line] = useState("");
  const [imgb4, setImgb4] = useState("");
  const [imgb4_hist, setImgb4_hist] = useState("");
  const [imgb4_scat, setImgb4_scat] = useState("");
  const [imgb4_pie, setImgb4_pie] = useState("");
  const [imgb4_line, setImgb4_line] = useState("");
  const [send, setSend] = useState("");
  const [mes, setMes] = useState("");
  const [outl, setOutl] = useState();
  const [error, setError] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  const RemoveOutl = () => {
    {
      axios.get(`/outlierremove/${uplo},${project}`).then((res) => {
        setOutl(res.data.status);
      });
    }
    window.location.reload();
    if (outl == 1) {
      setMes("Row Deleted");
      setError("");
    }
    if (outl !== 1) {
      setError("No Changes");
      setMes("");
    }
  };

  const plotoutlier = () => {
    if (cname == "") {
      setMes(undefined);
      return setError("You must Select Columns");
    } else if (quart1 == "") {
      setMes(undefined);
      return setError("You must Enter Fist Quartile");
    } else if (quart2 == "") {
      setMes(undefined);
      return setError("You must Enter Second Quartile");
    }
    {
      axios.get(`/outlierploting/${send}`).then((res) => {
        setUpper(res.data.n_upper),
          setLower(res.data.n_lower),
          setUploout(res.data.uplo),
          setStatus(res.data.status),
          setStatus_hist(""),
          setStatus_scat(""),
          setCName_pie(""),
          setImgb4(res.data.img_data),
          setError(""),
          setStatus_line("");
      });
      decrementNum();
    }
  };

  const plothistogram = () => {
    if (cname_hist == "") {
      setMes(undefined);
      return setError("You must Select Columns");
    }
    {
      axios.get(`/histogramploting/${send}`).then((res) => {
        setImgb4_hist(res.data.img_data),
          setStatus_hist(res.data.status),
          setStatus(""),
          setStatus_scat(""),
          setStatus_pie(""),
          setError(""),
          setStatus_line("");
      });
      decrementNum();
    }
  };

  const plotpiechart = () => {
    if (cname_pie == "") {
      setMes(undefined);
      return setError("You must Select Columns");
    }
    {
      axios.get(`/pieploting/${send}`).then((res) => {
        setImgb4_pie(res.data.img_data),
          setStatus_pie(res.data.status),
          setStatus(""),
          setStatus_scat(""),
          setError(""),
          setStatus_hist(""),
          setStatus_line("");
      });
      decrementNum();
    }
  };

  const plotscatter = () => {
    if (cname_scatter1 == "") {
      setMes(undefined);
      return setError("You must Select Column Y");
    }
    if (cname_scatter2 == "") {
      setMes(undefined);
      return setError("You must Select Column X");
    }
    {
      axios.get(`/scatterploting/${send}`).then((res) => {
        setImgb4_scat(res.data.img_data),
          setStatus_scat(res.data.status),
          setStatus(""),
          setStatus_pie(""),
          setStatus_hist(""),
          setStatus_line(""),
          setError("");
      });
      decrementNum();
    }
  };

  const plotLine = () => {
    if (cname_line1 == "") {
      setMes(undefined);
      return setError("You must Select Column Y");
    }
    if (cname_line2 == "") {
      setMes(undefined);
      return setError("You must Select Column X");
    }
    {
      axios.get(`/lineploting/${send}`).then((res) => {
        setImgb4_line(res.data.img_data),
          setStatus_line(res.data.status),
          setStatus(""),
          setStatus_pie(""),
          setStatus_hist(""),
          setError(""),
          setStatus_scat("");
      });
      decrementNum();
    }
  };

  useEffect(() => {
    if (num == 0) {
      axios.get(`/infodw/${project}`).then((res) => setColsDtype(res.data.coldtype));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Card id="delete-account">
                <SuiBox pt={1} pb={2} px={2}>
                  {error && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                    </SuiBox>
                  )}
                  {mes && (
                    <SuiBox mt={2} mb={2} textAlign="center">
                      <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                    </SuiBox>
                  )}
                  <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <Card id="delete-account">
                      <SuiBox p={2}>
                        <SuiBox pt={3} px={2}>
                          <SuiTypography variant="h6" fontWeight="medium">
                            Outliers
                          </SuiTypography>
                        </SuiBox>

                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <SuiBox
                              border={`${borderWidth[1]} solid ${borderColor}`}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={3}
                            >
                              <select
                                name="dtcol"
                                id="dtcol"
                                style={{
                                  width: "30%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName(event.target.value),
                                    setSend(
                                      String(project) +
                                        "=" +
                                        String(event.target.value) +
                                        "=" +
                                        quart1 +
                                        "=" +
                                        quart2
                                    );
                                }}
                              >
                                <option value="">--SELECT COLUMN--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <span>&nbsp;&nbsp;&nbsp;</span>
                              <SuiBox style={{ width: "25%", height: 35 }}>
                                <SuiInput
                                  onChange={(event) => {
                                    setQUARTILE1(event.target.value),
                                      setSend(
                                        String(project) +
                                          "=" +
                                          String(cname) +
                                          "=" +
                                          String(event.target.value) +
                                          "=" +
                                          quart2
                                      );
                                  }}
                                  type="text"
                                  placeholder="QUARTILE1"
                                />
                              </SuiBox>
                              <span>&nbsp;&nbsp;&nbsp;</span>
                              <SuiBox style={{ width: "25%", height: 35 }}>
                                <SuiInput
                                  onChange={(event) => {
                                    setQUARTILE2(event.target.value),
                                      setSend(
                                        String(project) +
                                          "=" +
                                          String(cname) +
                                          "=" +
                                          quart1 +
                                          "=" +
                                          String(event.target.value)
                                      );
                                  }}
                                  type="text"
                                  placeholder="QUARTILE2"
                                />
                              </SuiBox>

                              <span>&nbsp;&nbsp;&nbsp;</span>
                              <SuiButton
                                style={{ width: "20%", height: 35 }}
                                variant="gradient"
                                buttonColor="dark"
                                onClick={() => plotoutlier()}
                              >
                                Outliers
                              </SuiButton>
                            </SuiBox>

                            {cname && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column : {cname}
                              </SuiTypography>
                            )}
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            {quart1 && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Quartile 1 : {quart1}
                              </SuiTypography>
                            )}
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            {quart2 && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Quartile 2: {quart2}
                              </SuiTypography>
                            )}

                            <SuiBox pt={3} px={2}>
                              <SuiTypography variant="h6" fontWeight="medium">
                                Histogram
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox
                              border={`${borderWidth[1]} solid ${borderColor}`}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={3}
                            >
                              <select
                                name="dtcol"
                                id="dtcol"
                                style={{
                                  width: "50%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName_hist(event.target.value),
                                    setSend(String(project) + "=" + String(event.target.value));
                                }}
                              >
                                <option value="">--SELECT COLUMN--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <span>&nbsp;&nbsp;&nbsp;</span>

                              <SuiButton
                                style={{ width: "20%", height: 35 }}
                                variant="gradient"
                                buttonColor="dark"
                                onClick={() => plothistogram()}
                              >
                                Histogram
                              </SuiButton>
                            </SuiBox>

                            {cname_hist && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column : {cname_hist}
                              </SuiTypography>
                            )}

                            <SuiBox pt={3} px={2}>
                              <SuiTypography variant="h6" fontWeight="medium">
                                Pie Chart
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox
                              border={`${borderWidth[1]} solid ${borderColor}`}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={3}
                            >
                              <select
                                name="dtcol"
                                id="dtcol"
                                style={{
                                  width: "50%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName_pie(event.target.value),
                                    setSend(String(project) + "=" + String(event.target.value));
                                }}
                              >
                                <option value="">--SELECT COLUMN--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <span>&nbsp;&nbsp;&nbsp;</span>

                              <SuiButton
                                style={{ width: "20%", height: 35 }}
                                variant="gradient"
                                buttonColor="dark"
                                onClick={() => plotpiechart()}
                              >
                                Pie Plot
                              </SuiButton>
                            </SuiBox>

                            {cname_pie && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column : {cname_pie}
                              </SuiTypography>
                            )}

                            <SuiBox pt={3} px={2}>
                              <SuiTypography variant="h6" fontWeight="medium">
                                Scatter Plot
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox
                              border={`${borderWidth[1]} solid ${borderColor}`}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={3}
                            >
                              <select
                                name="dtcol"
                                id="dtcol"
                                style={{
                                  width: "30%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName_Scatter1(event.target.value),
                                    setSend(
                                      String(project) +
                                        "=" +
                                        String(event.target.value) +
                                        "=" +
                                        String(cname_scatter2)
                                    );
                                }}
                              >
                                <option value="">--SELECT X AXIS--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                name="dtcol1"
                                id="dtcol1"
                                style={{
                                  width: "30%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName_Scatter2(event.target.value),
                                    setSend(
                                      String(project) +
                                        "=" +
                                        String(cname_scatter1) +
                                        "=" +
                                        String(event.target.value)
                                    );
                                }}
                              >
                                <option value="">--SELECT Y AXIS--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <span>&nbsp;&nbsp;&nbsp;</span>

                              <SuiButton
                                style={{ width: "20%", height: 35 }}
                                variant="gradient"
                                buttonColor="dark"
                                onClick={() => plotscatter()}
                              >
                                ScatterPlot
                              </SuiButton>
                            </SuiBox>

                            {cname_scatter1 && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column x : {cname_scatter1}
                              </SuiTypography>
                            )}
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            {cname_scatter2 && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column y : {cname_scatter2}
                              </SuiTypography>
                            )}

                            <SuiBox pt={3} px={2}>
                              <SuiTypography variant="h6" fontWeight="medium">
                                Line Plot
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox
                              border={`${borderWidth[1]} solid ${borderColor}`}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={3}
                            >
                              <select
                                name="dtcol"
                                id="dtcol"
                                style={{
                                  width: "30%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName_Line1(event.target.value),
                                    setSend(
                                      String(project) +
                                        "=" +
                                        String(event.target.value) +
                                        "=" +
                                        String(cname_line2)
                                    );
                                }}
                              >
                                <option value="">--SELECT X AXIS--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <select
                                name="dtcol1"
                                id="dtcol1"
                                style={{
                                  width: "30%",
                                  height: 35,
                                  color: "#525557",
                                  backgroundColor: "#e4e9eb",
                                  border: "3px solid #e4e9eb",
                                }}
                                onChange={(event) => {
                                  setCName_Line2(event.target.value),
                                    setSend(
                                      String(project) +
                                        "=" +
                                        String(cname_line1) +
                                        "=" +
                                        String(event.target.value)
                                    );
                                }}
                              >
                                <option value="">--SELECT Y AXIS--</option>
                                {colsdtype.map(function (objt, index) {
                                  return (
                                    <option value={objt.col} key={index}>
                                      {objt.col}
                                    </option>
                                  );
                                })}
                              </select>
                              <span>&nbsp;&nbsp;&nbsp;</span>

                              <SuiButton
                                style={{ width: "20%", height: 35 }}
                                variant="gradient"
                                buttonColor="dark"
                                onClick={() => plotLine()}
                              >
                                LinePlot
                              </SuiButton>
                            </SuiBox>

                            {cname_line1 && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column x : {cname_scatter1}
                              </SuiTypography>
                            )}
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            {cname_line2 && (
                              <SuiTypography variant="caption" textColor="secondary">
                                Column y : {cname_scatter2}
                              </SuiTypography>
                            )}
                          </Grid>
                        </Grid>
                      </SuiBox>
                    </Card>
                    {colsdtype.map(function (objt, index) {
                      return (
                        <div key={index}>
                          <Bill
                            name={objt.col}
                            company={objt.datatypes}
                            email={objt.coltyp}
                            vat={objt.n_row}
                          />
                        </div>
                      );
                    })}
                  </SuiBox>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card className="h-100">
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={3}
                  px={2}
                >
                  <SuiBox display="flex" alignItems="flex-start">
                    <SuiTypography variant="button" textColor="text" fontWeight="regular">
                      {project}
                    </SuiTypography>
                    <SuiBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        home
                      </Icon>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
                {status && (
                  <SuiBox pt={3} pb={2} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Outlier&apos;s
                    </SuiTypography>
                    <SuiBox mb={2}>
                      <SuiTypography
                        variant="caption"
                        textColor="text"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        lower outliers
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                      customClass="no-list-style"
                    >
                      <Transaction
                        color="error"
                        icon="arrow_downward"
                        name="Lower Outlier's"
                        description={quart1}
                        value={n_lower && "Total Lower Outlier's : " + n_lower}
                      />
                    </SuiBox>
                    <SuiBox mt={1} mb={2}>
                      <SuiTypography
                        variant="caption"
                        textColor="text"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        Upper outliers
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                      customClass="no-list-style"
                    >
                      <Transaction
                        color="success"
                        icon="arrow_upward"
                        name="Upper outliers"
                        description={quart2}
                        value={n_upper && "Total Upper Outlier's : " + n_upper}
                      />
                    </SuiBox>
                    <Grid item xs={12} xl={12}>
                      <img width="100%" src={`data:image/jpeg;base64,${imgb4}`} />
                    </Grid>
                    <SuiButton
                      style={{ width: "100%", height: 35 }}
                      variant="gradient"
                      buttonColor="primary"
                      onClick={() => RemoveOutl()}
                    >
                      Remove outliers
                    </SuiButton>
                  </SuiBox>
                )}

                {status_hist && (
                  <SuiBox pt={3} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Histogram
                    </SuiTypography>

                    <Grid item xs={12} xl={12}>
                      <img width="100%" src={`data:image/jpeg;base64,${imgb4_hist}`} />
                    </Grid>
                  </SuiBox>
                )}

                {status_scat && (
                  <SuiBox pt={3} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Scatter Plot
                    </SuiTypography>

                    <Grid item xs={12} xl={12}>
                      <img width="100%" src={`data:image/jpeg;base64,${imgb4_scat}`} />
                    </Grid>
                  </SuiBox>
                )}

                {status_pie && (
                  <SuiBox pt={3} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Pie Plot
                    </SuiTypography>

                    <Grid item xs={12} xl={12}>
                      <img width="100%" src={`data:image/jpeg;base64,${imgb4_pie}`} />
                    </Grid>
                  </SuiBox>
                )}

                {status_line && (
                  <SuiBox pt={3} px={2}>
                    <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Line Plot
                    </SuiTypography>

                    <Grid item xs={12} xl={12}>
                      <img width="100%" src={`data:image/jpeg;base64,${imgb4_line}`} />
                    </Grid>
                  </SuiBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Billing;
