import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
// const params = new URLSearchParams(document.location.search);
import Bill from "layouts/dropfunction/components/Bill";
function BillingInformation() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [colsdtype, setColsDtype] = useState([]);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  useEffect(() => {
    if (num == 0) {
      axios.get(`/dtypechange/${project}`).then((res) => setColsDtype(res.data.coldtype));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Delete Columns
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {colsdtype.map(function (objt, index) {
            return (
              <div key={index}>
                <Bill name={objt.col} company={objt.nulls} email={objt.n_row} vat={objt.coltyp} />
              </div>
            );
          })}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;
