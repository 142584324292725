import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Invoices from "layouts/cleanfunction/components/Invoices";
import { useAuth } from "../../auth-context/auth.context";
import { useState } from "react";
import SuiTypography from "components/SuiTypography";
function Billing() {
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  let { user } = useAuth();
  const newdw = () => {
    const uploadData = new FormData();
    uploadData.append("project", project);
    uploadData.append("user", user["username"]);
    fetch("https://mizube.oud.ai/api/users/datawarehouse", {
      method: "POST",
      body: uploadData,
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status == 0) {
          {
            setMes("");
          }
          {
            setError("Same Name Data WareHouse Exist ");
          }
        }
        if (data.status == 1) {
          {
            setError("");
          }
          {
            setMes(" Successfully Saved Data Ware House");
          }
          if (data.status == -1) {
            {
              setMes("");
            }
            {
              setError("Unknown Error");
            }
          }
        }
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiTypography>{project}</SuiTypography>
      <SuiBox mt={4}>
        <SuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <DefaultInfoCard
                    icon="air"
                    title="data types"
                    description="Change Data Types"
                    value={<a href={"/#/dtypefunction/?project=" + project}>PROCESS</a>}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <DefaultInfoCard
                    icon="Nan"
                    title="null value"
                    description="Fill Null Values"
                    value={<a href={"/#/fillnullfunction/?project=" + project}>PROCESS</a>}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <DefaultInfoCard
                    icon="remove"
                    title="drop cols"
                    description="Drop Unwanted Cols"
                    value={<a href={"/#/dropfunction/?project=" + project}>PROCESS</a>}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <DefaultInfoCard
                    icon="numbers"
                    title="data encode"
                    description="Encode Data"
                    value={<a href={"/#/encodefunction/?project=" + project}>PROCESS</a>}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <DefaultInfoCard
                    icon="computer"
                    title="compiler"
                    description="Python Compiler"
                    value={<a href={"/#/pythoncompiler/?project=" + project}>PROCESS</a>}
                  />
                </Grid>

                <Grid item xs={12} md={6} xl={4}>
                  <DefaultInfoCard
                    icon="chat"
                    title="Remove Special Character"
                    description="Applay Regular Expession"
                    value={<a href={"/#/regularexpr/?project=" + project}>PROCESS</a>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Invoices />
              <br />
              <SuiButton variant="gradient" buttonColor="success" onClick={() => newdw()}>
                <Icon className="font-bold">house</Icon>&nbsp;&nbsp;&nbsp;SAVE AS PROCESSED DATA
              </SuiButton>
              {error && (
                <SuiBox mt={2} mb={2} textAlign="left">
                  <h6 style={{ color: "red", textAlign: "left" }}>{error}</h6>
                </SuiBox>
              )}
              {mes && (
                <SuiBox mt={2} mb={2} textAlign="left">
                  <h6 style={{ color: "green", textAlign: "left" }}>{mes}</h6>
                </SuiBox>
              )}
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Billing;
