import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Webcam from "react-webcam";
import SuiButton from "components/SuiButton";
import React, { useState, useEffect } from "react";
import axios from "api/axiosInstance";
import { useAuth } from "auth-context/auth.context";
const videoConstraints = {
  width: 490,
  height: 500,
  facingMode: "user",
};

const Profile = () => {
  const [picture, setPicture] = useState("");
  const [folname, setFolname] = useState("");
  const webcamRef = React.useRef(null);
  const [datas3bucket, setS3Bucket] = useState([]);
  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }
  let { user } = useAuth();
  useEffect(() => {
    if (num == 0) {
      axios
        .get(`/s3bucket`, {
          params: {
            username: user["username"],
          },
        })
        .then((res) => setS3Bucket(res.data))
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const save = () => {
    const uploadData = new FormData();
    if (folname == "") {
      setMes(undefined);
      return setError("Select A Folder");
    } else if (picture == "") {
      setMes(undefined);
      return setError("Capture An Image");
    } else {
      uploadData.append("folname", folname);
      uploadData.append("picture", picture);
      uploadData.append("user", user["username"]);

      fetch("https://mizube.oud.ai/api/users/s3fileimg", {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status == -1) {
            {
              setMes("");
            }
            {
              setError("Unknown Error");
            }
          }

          if (data.status == 1) {
            {
              setError("");
            }
            {
              setMes(" Successfully Uploaded");
            }
            decrementNum();
          }
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={4}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <div className="container">
                <div>
                  <div>
                    {picture == "" ? (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    ) : (
                      <img src={picture} />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} xl={1}></Grid>

            <Grid item xs={12} md={6} xl={3}>
              <div>
                {picture != "" ? (
                  <a href="/captureimage">
                    <SuiButton variant="gradient" buttonColor="primary" fullWidth>
                      RETAKE
                    </SuiButton>
                  </a>
                ) : (
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    fullWidth
                  >
                    CAPTURE
                  </SuiButton>
                )}
              </div>

              <div>
                <br></br>

                {picture && (
                  <select
                    name="dtype"
                    id="d_type"
                    style={{
                      width: "100%",
                      height: 35,
                      color: "#525557",
                      backgroundColor: "#e4e9eb",
                      border: "3px solid #e4e9eb",
                    }}
                    onChange={(event) => {
                      setFolname(event.target.value);
                    }}
                  >
                    <option value="">--SELECT--</option>
                    {datas3bucket.map((s3, index) => {
                      return (
                        <option key={index} value={s3.Fname}>
                          {s3.Fname}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div>
                {error && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                  </SuiBox>
                )}
                {mes && (
                  <SuiBox mt={2} mb={2} textAlign="center">
                    <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                  </SuiBox>
                )}
              </div>
              <div>
                <br></br>
                {picture && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="light"
                    onClick={(e) => {
                      e.preventDefault();
                      save();
                    }}
                    fullWidth
                  >
                    SAVE IMAGE
                  </SuiButton>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={6} xl={4}></Grid>
          </Grid>
        </SuiBox>
        <SuiBox>
          <SuiBox></SuiBox>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
};

export default Profile;
