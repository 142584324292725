import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Bill from "layouts/dtypefunction/components/Bill";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import SuiButton from "components/SuiButton";
import { Grid } from "@mui/material";
import borders from "assets/theme/base/borders";
// import SuiInput from "components/SuiInput";
// const params = new URLSearchParams(document.location.search);
function BillingInformation() {
  const { borderWidth, borderColor } = borders;
  const currentUrl = window.location.href;
  const project = currentUrl.split("?")[1].split("=")[1];
  const [num, setNum] = useState(0);
  const [colsdtype, setColsDtype] = useState([]);
  const [send, setSend] = useState("");
  const [mes, setMes] = useState("");
  const [error, setError] = useState(undefined);
  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  // function decrementNum() {setNum(prev => prev - 1);}

  const Compiler = () => {
    console.log(send);
    {
      axios.get(`/pycompiler/${send}`).then((res) => {
        if (res.data.status == 1) {
          window.location.reload();
        } else if (res.data.status == 0) {
          {
            setMes("");
          }
          {
            setError("Invalid Code ");
          }
        }
      });
    }
  };

  useEffect(() => {
    if (num == 0) {
      axios.get(`/dtypechange/${project}`).then((res) => setColsDtype(res.data.coldtype));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          PYTHON COMPILER
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Dataframe Name :data
          <br></br>
          Instruction: Use DOT instead of .
        </SuiTypography>
      </SuiBox>

      {error && (
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
        </SuiBox>
      )}
      {mes && (
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
        </SuiBox>
      )}
      <SuiBox pt={1} pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Card id="delete-account">
            <SuiBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <SuiBox
                    border={`${borderWidth[1]} solid ${borderColor}`}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <SuiBox style={{ width: "100%", height: 120 }}>
                      <textarea
                        style={{ width: "100%", height: 120 }}
                        onChange={(event) => {
                          setSend(String(project) + "->" + String(event.target.value));
                        }}
                        id="compiler"
                        name="python"
                        rows="8"
                        cols="50"
                        placeholder="PYTHON CODE"
                      ></textarea>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox>
                    <br></br>
                    <SuiButton
                      style={{ width: "20%", height: 35 }}
                      variant="gradient"
                      buttonColor="dark"
                      onClick={() => Compiler()}
                    >
                      update
                    </SuiButton>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
          {colsdtype.map(function (objt, index) {
            return (
              <div key={index}>
                <Bill
                  name={objt.col}
                  company={objt.datatypes}
                  email={objt.coltyp}
                  vat={objt.n_row}
                />
              </div>
            );
          })}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;
