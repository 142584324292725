import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useState } from "react";
import { useAuth } from "../../auth-context/auth.context";
// const params = new URLSearchParams(document.location.search);

function Tables(outlined) {
  let { user } = useAuth();
  // const s3 = params.get("s3");
  const currentUrl = window.location.href;
  const s3 = currentUrl.split("?")[1].split("=")[1];
  const classes = styles();
  const [pname, setpName] = useState("");
  const [error, setError] = useState(undefined);
  const [mes, setMes] = useState("");
  const newdf = () => {
    const uploadData = new FormData();
    if (pname == "") {
      setMes(undefined);
      return setError("Enter Project Name");
    } else {
      try {
        uploadData.append("pname", pname);
        uploadData.append("s3fname", s3);
        uploadData.append("user", user["username"]);

        fetch("https://mizube.oud.ai/api/users/dlake", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data.status == 0) {
              {
                setMes("");
              }
              {
                setError(" Can't Upload, Project Name Already Exist");
              }
            }
            if (data.status == 1) {
              {
                setError("");
              }
              {
                setMes(" Successfully Uploaded");
              }
              if (data.status == -1) {
                {
                  setMes("");
                }
                {
                  setError("Unknown Error");
                }
              }
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid item xs={12} md={6} xl={3}>
          <Card
            raised
            className={clsx(classes.placeholderCard, {
              [classes.placeholderCard_outlined]: outlined,
            })}
          >
            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="100%"
              p={3}
            >
              <div>
                <SuiBox mb={1} ml={0.5}>
                  <SuiInput
                    value={pname}
                    onChange={(event) => {
                      setpName(event.target.value);
                    }}
                    type="text"
                    placeholder="Project Name"
                  />
                </SuiBox>
                <SuiBox mb={1} ml={0.5}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    onClick={() => newdf()}
                    fullWidth
                  >
                    upload
                  </SuiButton>
                </SuiBox>
              </div>
              {error && (
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
                </SuiBox>
              )}
              {mes && (
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
                </SuiBox>
              )}
            </SuiBox>
          </Card>

          <Card></Card>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
