import Card from "@mui/material/Card";
import clsx from "clsx";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import SuiInput from "components/SuiInput";
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import axios from "api/axiosInstance";
import Header from "layouts/profile/components/Header";

function Infotxt(outlined) {
  const classes = styles();
  const [proinfo, setProinfo] = useState("");
  const [proinfos, setProinfos] = useState("");
  const [proinfoh, setProinfoh] = useState("");
  const [binfo, setBinfo] = useState("");
  const [binfos, setBinfos] = useState("");
  const [binfoh, setBinfoh] = useState("");
  const [error, setError] = useState(undefined);

  const [num, setNum] = useState(0);
  const [mes, setMes] = useState("");

  const [Pinfo, setPInfo] = useState("");
  const [Binfo, setBInfo] = useState("");

  const [Pinfoh, setPInfoh] = useState("");
  const [Binfoh, setBInfoh] = useState("");

  const [Pinfos, setPInfos] = useState("");
  const [Binfos, setBInfos] = useState("");

  function incrementNum() {
    setNum((prev) => prev + 1);
  }
  function decrementNum() {
    setNum((prev) => prev - 1);
  }

  useEffect(() => {
    if (num == 0) {
      axios
        .get("/infotxt")
        .then(
          (res) => (
            setPInfo(res.data[0].txt1),
            setBInfo(res.data[0].txt2),
            setPInfoh(res.data[0].txt3),
            setBInfoh(res.data[0].txt4),
            setPInfos(res.data[0].txt5),
            setBInfos(res.data[0].txt6)
          )
        )
        .catch((err) => console.log(err));
    }
    if (num < 1) {
      incrementNum();
    }
  });

  const newdf = () => {
    const uploadData = new FormData();
    if (proinfo == "") {
      setMes(undefined);
      return setError("Enter Project Info");
    } else if (binfo == "") {
      setMes(undefined);
      return setError("Enter Business Info");
    } else if (proinfoh == "") {
      setMes(undefined);
      return setError("Enter Business Info Heading");
    } else if (binfoh == "") {
      setMes(undefined);
      return setError("Enter Business Info Heading");
    } else if (proinfos == "") {
      setMes(undefined);
      return setError("Enter Business Info Description");
    } else if (binfos == "") {
      setMes(undefined);
      return setError("Enter Business Info Description");
    } else {
      try {
        uploadData.append("proinfo", proinfo);
        uploadData.append("binfo", binfo);

        uploadData.append("proinfoh", proinfoh);
        uploadData.append("proinfos", proinfos);

        uploadData.append("binfoh", binfoh);
        uploadData.append("binfos", binfos);
        fetch("https://mizube.oud.ai/api/users/infotxt", {
          method: "POST",
          body: uploadData,
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data.status == 0) {
              {
                setMes("");
              }
            }

            if (data.status == 1) {
              {
                setError("");
              }
              decrementNum();
            }

            if (data.status == -1) {
              {
                setMes("");
              }
              {
                setError("Unknown Error");
              }
            }
          })

          .catch((error) => console.error(error));
      } catch {
        {
          setMes("");
        }
        {
          setError("Upload File & Project Name Properly");
        }
      }
    }
    setError(undefined);
    decrementNum();
    return setMes(mes);
  };

  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={2} mb={3}>
        <Card
          raised
          className={clsx(classes.placeholderCard, {
            [classes.placeholderCard_outlined]: outlined,
          })}
        >
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            height="100%"
            p={3}
          >
            <div>
              <SuiBox display="flex" mb={1} ml={1.5}>
                <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                  Heading : {Pinfoh}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={proinfoh}
                  onChange={(event) => {
                    setProinfoh(event.target.value);
                  }}
                  type="text"
                  placeholder="PROJECT INFORMATION HEADING"
                />
              </SuiBox>
              <SuiBox display="flex" mb={1} ml={1.5}>
                <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                  Description: {Pinfos}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={proinfos}
                  onChange={(event) => {
                    setProinfos(event.target.value);
                  }}
                  type="text"
                  placeholder="PROJECT INFORMATION SHORT"
                />
              </SuiBox>
              <SuiBox display="flex" mb={1} ml={1.5}>
                <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                  Info :{Pinfo}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={proinfo}
                  onChange={(event) => {
                    setProinfo(event.target.value);
                  }}
                  type="text"
                  placeholder="PROJECT INFORMATION FULL"
                />
              </SuiBox>
              <SuiBox display="flex" mb={1} ml={1.5}>
                <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                  Heading :{Binfoh}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={binfoh}
                  onChange={(event) => {
                    setBinfoh(event.target.value);
                  }}
                  type="text"
                  placeholder="BUSINESS INFORMATION HEADING"
                />
              </SuiBox>
              <SuiBox display="flex" mb={1} ml={1.5}>
                <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                  Description:{Binfos}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={binfos}
                  onChange={(event) => {
                    setBinfos(event.target.value);
                  }}
                  type="text"
                  placeholder="BUSINESS INFORMATION SHORT"
                />
              </SuiBox>
              <SuiBox display="flex" mb={1} ml={1.5}>
                <SuiTypography variant="caption" fontWeight="medium" textColor="text">
                  Info :{Binfo}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiInput
                  value={binfo}
                  onChange={(event) => {
                    setBinfo(event.target.value);
                  }}
                  type="text"
                  placeholder="BUSINESS INFORMATION FULL"
                />
              </SuiBox>

              <SuiBox mb={1} ml={0.5}>
                <SuiButton variant="gradient" buttonColor="dark" onClick={() => newdf()} fullWidth>
                  UPDATE
                </SuiButton>
              </SuiBox>
            </div>
            {error && (
              <SuiBox mt={2} mb={2} textAlign="center">
                <h6 style={{ color: "red", textAlign: "center" }}>{error}</h6>
              </SuiBox>
            )}
            {mes && (
              <SuiBox mt={2} mb={2} textAlign="center">
                <h6 style={{ color: "green", textAlign: "center" }}>{mes}</h6>
              </SuiBox>
            )}
          </SuiBox>
        </Card>
        <Card></Card>
        <Footer />
      </SuiBox>
    </DashboardLayout>
  );
}

export default Infotxt;
