import axios from "./axiosInstance";

class AuthApi {
  static Login = (data) => {
    return axios.post(`/login`, data);
  };

  static Register = (data) => {
    return axios.post(`/register`, data);
  };

  static Logout = (data) => {
    return axios.post(`/logout`, data, { headers: { Authorization: `${data.token}` } });
  };
}

export default AuthApi;
